
export default {
  props: {
    negative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      site: process.env.site,
    }
  }
}
