
export default {
  props: ['error'],
  computed: {
    message() {
      // let defaultMsg = this.error.statusCode === 404 ? 'Siden finnes ikke' : 'En feil oppsto';
      return this.error.statusCode === 404 ? 'Siden finnes ikke' : 'En feil oppsto';
    }
  }
}
