import _ from 'lodash';

export default ({app, $axios, query}) => {
  app.seo = (seo) => {
    if (!seo) {
      return null;
    }
    let jsondl = _.map(seo.MetaJsonLdContainer, (value) => {
      // consola.log(value);
      return {type: 'application/ld+json', innerHTML: JSON.stringify(value)}
    });
    let script = _.map(seo.MetaScriptContainer, (value) => {
      // consola.log(value);
      return {innerHTML: value['script']}
    });

    return {
      title: seo.MetaTitleContainer.title.title,
      meta: _.flatten(_.values(seo.MetaTagContainer)),
      link: _.flatten(_.values(seo.MetaLinkContainer)),
      script: jsondl.concat(script),
      __dangerouslyDisableSanitizers: ['script'],
    }
  }
  /**
   * Adds global meta method to context.app to get metadata on the current URI
   */
  // consola.log('meta');
  app.meta = async (route) => {
    if (route.query.token) {
      return false;
    }
    // const seo = await $axios.$get('/api/meta?uri=' + route.path);
    const seo = await $axios.$get(`/actions/seomatic/meta-container/all-meta-containers?uri=${route.path}&asArray=true&siteId=${process.env.siteId}`);
    return seo;
  };
}
