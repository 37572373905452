import Vue from 'vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js';
import 'plyr/dist/plyr.css';

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen']
  },
  emit: ['ended']
})
