
import {mapActions, mapState} from 'vuex';

export default {
  props: ['type'],
  data() {
    return {
      site: process.env.site,
      siteTitle: process.env.settings.title,
      menuHover: false,
      visFilter: this.$route.name === 'tildeling',
    }
  },
  watch: {
    $route(to, from) {
      // this.menuOpen = false;
      // console.log(from, to);
      if (to.name === 'tildeling' && from.name !== 'tildeling') {
        this.mode = 1;
      }

      this.visFilter = to.name === 'tildeling';
    }
  },
  methods: {
    offset(i) {
      let offset = null;
      switch (i) {
        case 1:
          offset = "md:mt-8";
          break;
        case 2:
          offset = "md:mt-16";
          break;
        case 3:
          offset = "md:mt-24";
          break;
        case 4:
          offset = "md:mt-32";
          break;
      }
      return offset;
    }
  },
  computed: {
    phone() {
      return !this.$screen.md
    },
    skjulHeader() {
      return this.visFilter && this.phone
    },
    sokKnapp() {
      return this.globals.sokKnapp || null;
    },
    ...mapState({
      navigation: state => state.navigation.items.header,
      globals: state => state.globals,
      mittSok: state => state.mittSok
    }),
    mode: {
      get() {
        return this.$store.state.navigation.mode
      },
      set(value) {
        this.$store.commit('setMode', value)
      }
    },
    menuOpen: {
      get() {
        return this.$store.state.navigation.open
      },
      set(value) {
        this.$store.commit('setMenu', value)
      }
    },
    visFilterMenu: {
      get() {
        return this.$store.state.navigation.visFilterMenu
      },
      set(value) {
        this.$store.commit('setVisFilter', value)
      }
    }
  }
}
