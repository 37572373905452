import {cacheAdapterEnhancer} from 'axios-extensions'
import LRUCache from 'lru-cache';
import consola from 'consola';

const ONE_HOUR = 1000 * 60 * 60
const ONE_DAY = 24 * ONE_HOUR

const defaultCache = new LRUCache({maxAge: ONE_DAY})

export default ({$axios, env}) => {
  const defaults = $axios.defaults;
  // consola.log(app.$axios.defaults)
  // https://github.com/kuitos/axios-extensions
  defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache
  })

  $axios.onRequest(config => {
    config.params = config.params || {};
    config.params.site = env.site;
    // consola.info('Making request to ' + config.url)
    // consola.log(config)
  })
}
