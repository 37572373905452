
export default {
  name: 'cta-button',
  props: {
    url: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '_self'
    },
    stil: {
      type: String,
      default: 'hvit'
    },
    size: {
      type: String,
      default: 'liten'
    },
    liten: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkExternal(url) {
      let checkExternal = new RegExp("^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$");
      let test = checkExternal.test(url);
      // console.log(test);
      return test;
    }
  },
  computed: {
    storrelse() {
      if (this.liten) {
        this.size = 'liten';
      }
      switch (this.size) {
        case 'mini':
          return 'p-1 px-3 text-xs';
          break;
        case 'liten':
          return 'p-2 px-4 text-sm';
          break;
        default:
          return 'p-3 px-6';
          break;
      }
    },
    klasse() {
      switch (this.stil) {
        case 'ingen':
          return 'text-blue border-2 border-blue';
          break;
        case 'blaa':
          return 'bg-blue text-white border-2 border-blue';
        case 'graa':
          return 'bg-gray-200 text-green-700 border-2 border-gray-200';
        case 'hvit':
        default:
          return 'bg-white text-blue border-2 border-blue';
          break;
      }
    },
    external() {
      return this.checkExternal(this.url);
    }
  }
}
