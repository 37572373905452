export const state = () => ({
  isDev: false,
  tildeling: {},
  kategorier: [],
  kommuner: [],
  regioner: [],
  mittSok: null,
  dataRange: {
    min: null,
    max: null
  },
  navigation: {
    items: {},
    open: false,
    mode: 1,
    visFilterMenu: false,
  },
  globals: {},
  sparekonto: null
})

import moment from 'moment-timezone';

moment.locale('nb');
moment.tz("Europe/Oslo");

export const mutations = {
  setTildeling: (state, payload) => {
    let kategorier = payload.map(marker => marker.kategori),
      regioner = payload.map(marker => marker.region),
      kommuner = payload.map(marker => marker.kommune);

    payload.forEach((marker) => {
      marker.timestamp = parseInt(moment(marker.godkjent, "YYYY-MM-DD").format("x").toString());
    });

    const range = payload.map(x => x.timestamp);
    state.dataRange = Object.freeze({
      min: parseInt(moment(Math.min(...range)).startOf('month').format('x').toString()),
      max: parseInt(moment(Math.max(...range)).endOf('month').format('x').toString())
    });

    kategorier = kategorier.map(JSON.stringify).reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
      .filter(function(item, index, arr) {
        return arr.indexOf(item, index + 1) === -1;
      }) // check if there is any occurence of the item in whole array
      .reverse().map(JSON.parse);

    state.kategorier = Object.freeze(kategorier.sort(function(a, b) {
      return a.navn.toUpperCase().localeCompare(b.navn.toUpperCase())
    }));
    state.kommuner = Object.freeze(kommuner.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i).sort(function(a, b) {
      return a.navn.toUpperCase().localeCompare(b.navn.toUpperCase())
    }));
    // TODO: Aktiver når regioner er på plass
    // state.regioner = Object.freeze(regioner.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i).sort(function(a, b) {
    //   return a.navn.toUpperCase().localeCompare(b.navn.toUpperCase())
    // }));
    state.regioner = regioner;
    state.tildeling = Object.freeze(payload);
  },
  setNavigation: (state, navigation) => {
    state.navigation.items = Object.freeze(navigation);
  },
  setGlobals: (state, globals) => {
    state.globals = Object.freeze(globals);
  },
  setSparekonto: (state, sparekonto) => {
    state.sparekonto = Object.freeze(sparekonto);
  },
  setMenu: (state, payload) => {
    state.navigation.open = payload;
    state.navigation.visFilterMenu = false;
  },
  setMittSok: (state, payload) => {
    state.mittSok = payload;
  },
  setMode: (state, payload) => {
    state.navigation.mode = payload;
  },
  setVisFilter: (state, payload) => {
    state.navigation.visFilterMenu = payload;
    state.navigation.open = false;
  },
  setDev: (state, payload) => {
    state.isDev = payload;
  }
};

export const actions = {
  async setTildeling({commit}) {
    const data = require('~/tildeling.json');
    await commit('setTildeling', data.data);
  },
  async setGlobals({commit}) {
    const {data} = await this.$axios.get("/api/globals", {useCache: true});
    const {globals, navigation, sparekonto} = data;

    commit('setNavigation', navigation);
    commit('setGlobals', globals);
    commit('setSparekonto', sparekonto);
    // consola.success('Success!', sparekonto);
  },
  setMenu({commit}, payload) {
    commit('setMenu', payload);
  },
  setMode({commit}, payload) {
    commit('setMode', payload);
  },
  setMittSok({commit}, payload) {
    commit('setMittSok', payload);
  },
  setVisFilter({commit}, payload) {
    commit('setVisFilter', payload);
  },
  async nuxtServerInit({commit, dispatch}, {isDev, route}) {
    commit('setDev', isDev);
    await dispatch('setGlobals');
  }
}

export const getters = {
  getTildeling(state) {
    return state.tildeling;
  }
}
