import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=f8974430&v-else=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=f8974430&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CtaButton: require('/workspace/frontend/components/CtaButton.vue').default,Logo: require('/workspace/frontend/components/Logo.vue').default,SvgBilde: require('/workspace/frontend/components/SvgBilde.vue').default})
