import lazySizes from 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';

document.addEventListener('lazybeforeunveil', (e) => {
  if (e.target.tagName == 'image') {
    const imageEl = e.target,
      src = imageEl.dataset.src || null;
    if (imageEl && src) {
      // console.log(imageEl);
      imageEl.setAttribute('xlink:href', src);
    }
  }
});
export default lazySizes
