import consola from 'consola';

export default async ({$axios, enablePreview, query, env}) => {
  let preview = Object.keys(query).some(res => res.includes("preview"));
  // console.log(preview);
  if (preview) {
    consola.info('Enabled preview');
    await $axios.interceptors.request.use(
      (config) => {
        config.params = config.params || {};
        if (query.token) {
          config.params = {...query, ...config.params}
        }
        return config
      },
      (err) => {
        return Promise.reject(err)
      }
    )

    enablePreview();
    document.body.classList.add("preview");
  }
}
