

import {uuid} from 'vue-uuid';

export default {
  props: {
    bilde: {
      type: String
    },
    type: {
      default: 'enkel',
      type: String
    },
    byline: {
      default: '',
      type: String
    },
    skygge: {
      default: true,
      type: Boolean
    },
    rotate: {
      default: '0',
      type: String
    }
  },

  data() {
    return {
      id: uuid.v4(),
    }
  },
}
